import { render, staticRenderFns } from "./EventsView.vue?vue&type=template&id=210864d1&scoped=true"
import script from "./EventsView.vue?vue&type=script&lang=js"
export * from "./EventsView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "210864d1",
  null
  
)

export default component.exports