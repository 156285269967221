<template>
  <div>
    <v-navigation-drawer
      v-model="visible"
      absolute
      bottom
      temporary
      right
      :width="1024"
    >
      <div class="p-6">
        <h1 class="font-semibold text-[18px] mb-4">Variant {{ title }}</h1>

        <h1 class="font-semibold text-[14px] w-full">
          <span v-if="currentDate" class="mb-2.5 text-[14px] font-semibold">
            {{ returnMonth(currentDate.start.date) }}
          </span>
        </h1>

        <div class="flex items-center justify-between w-full">
          <div class="flex items-center w-full">
            <v-btn icon class="ma-2" @click="$refs.calendarVariant.prev()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon class="ma-2" @click="$refs.calendarVariant.next()">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>

          <div class="flex items-center">
            <div>
              <v-icon
                dense
                @click="deleteCalendarPage"
                size="20"
                style="padding: 3px; margin-right: 16px"
                color="red darken-4"
              >
                mdi-delete-outline
              </v-icon>
            </div>

            <div>
              <v-icon
                dense
                @click="copyCalendarPage"
                small
                style="padding: 5px; margin-right: 16px"
                color="grey darken-4"
              >
                mdi-content-copy
              </v-icon>
            </div>

            <div>
              <v-icon
                dense
                @click="handleOnEventInsert(getCopiedEventData, true)"
                small
                style="padding: 5px; margin-right: 16px"
                color="grey darken-4"
                :disabled="isPasteBtnDisabled"
              >
                mdi-content-paste
              </v-icon>
            </div>
          </div>
        </div>

        <v-calendar
          style="min-height: 600px"
          ref="calendarVariant"
          v-model="value"
          :weekdays="weekday"
          :type="type"
          :events="liveEvents"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          @click:event="handleOnCalendarEvent"
          @click:date="handleOnClickDay"
          :event-ripple="false"
          :event-text-color="''"
          event-end="end_date"
          event-start="start_date"
          @change="handleOnChangeCalendar"
        >
          <template v-slot:event="{ event }">
            <div>
              <div>
                <div
                  class="flex items-center px-1.5"
                  style="line-height: normal"
                >
                  <span
                    class="font-semibold text-[10px] mr-1"
                    v-if="isEventDeleted(event)"
                  >
                    (Delete)
                  </span>
                  <v-icon
                    size="18"
                    class="ml-1.5 mr-0"
                    :color="event.vuetify_color"
                  >
                    {{ `mdi-${event.type_icon}` }}
                  </v-icon>
                  <div
                    class="ml-1.5 font-semibold text-[11px] flex items-center"
                  >
                    <span>
                      {{ event.event_name }}
                    </span>
                    <span class="ml-1.5" v-if="event.alias">
                      ({{ event.alias }})</span
                    >
                  </div>

                  <span
                    class="ml-1.5 text-[11px] font-semibold"
                    v-if="event.isRecurring"
                  >
                    Recurring - For each
                    {{ event.recurringOptions.period.value }}
                    {{ event.recurringOptions.period.mode.toLowerCase() }} -
                    {{ event.recurringOptions.amount }} times
                  </span>
                </div>
              </div>
            </div>
          </template>

          <!-- <template v-slot:day-label="{ day }">
            <div class="flex items-center justify-center">
              <div
                class="font-semibold text-[10px] hover:bg-gray-100 min-w-[40px] min-h-[40px] rounded-full leading-[40px]"
              >
                {{ day }}
              </div>
            </div>
          </template> -->
        </v-calendar>
      </div>

      <div class="flex items-center justify-end px-6 pt-0">
        <v-btn
          color="secondary"
          depressed
          class="font-semibold"
          @click="submitVariant"
        >
          Submit
        </v-btn>
      </div>

      <VariantUpdateDialog
        :show="showUpdateDialog"
        @closed="showUpdateDialog = false"
        :data="showUpdateDialogData"
        :mode="showUpdateDialogMode"
        :games="games"
        :eventTypes="eventTypes"
        :events="events"
        @update="handleOnEventUpdate"
        :insertDay="insertDay"
        @delete="handleOnEventDelete"
        @insert="handleOnEventInsert($event, false)"
      ></VariantUpdateDialog>
    </v-navigation-drawer>
  </div>
</template>

<script>
import VariantUpdateDialog from "./VariantUpdateDialog.vue";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      visible: false,
      type: "month",
      types: ["month", "week", "day", "4day"],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
      liveEvents: [],
      currentDate: null,
      showUpdateDialogData: null,
      showUpdateDialogMode: null,
      showUpdateDialog: false,
      insertDay: null,
    };
  },
  props: {
    games: {
      type: Array,
      default: () => [],
    },
    eventTypes: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
    data(newValue) {
      this.liveEvents = newValue ? JSON.parse(JSON.stringify(newValue)) : [];
    },
  },
  methods: {
    ...mapActions(["setCopiedEventData"]),

    submitVariant() {
      this.$emit("submit", this.index, this.liveEvents);
      this.visible = false;
    },

    deleteCalendarPage() {
      const start_date = moment(
        this.$refs.calendarVariant.lastStart.date,
        "YYYY-MM-DD"
      );
      const end_date = moment(
        this.$refs.calendarVariant.lastEnd.date,
        "YYYY-MM-DD"
      );

      const events = this.liveEvents.filter((x) => {
        const eventStartDate = moment(x.start_date, "YYYY-MM-DD");
        if (eventStartDate >= start_date && eventStartDate <= end_date) {
          return true;
        }
        return false;
      });

      for (let i = 0; i < events.length; i++) {
        const event = events[i];
        this.deleteLiveEventFromCalendar(event, false);
      }
    },

    async deleteLiveEventFromCalendar(data, withRecurrings) {
      this.showUpdateDialog = false;
      this.showUpdateDialogData = null;
      // Delete live event from deployment.
      if (data.id) {
        const index = this.liveEvents.findIndex((x) => x.id === data.id);
        if (index > -1) {
          if (withRecurrings) {
            for (let i = 0; i < this.liveEvents.length; i++) {
              const scheduleEvent = this.liveEvents[i];
              if (scheduleEvent.event_id === data.event_id) {
                this.liveEvents.splice(i, 1);
              }
            }
          } else {
            this.liveEvents.splice(index, 1);
          }
        }
      } else {
        if (data.tempId) {
          this.liveEvents = this.liveEvents.filter(
            (x) => x.tempId != data.tempId
          );
        }
      }

      this.$toast.success(
        "You have successfully removed the live event from calendar.",
        {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        }
      );
    },

    copyCalendarPage() {
      const start_date = moment(
        this.$refs.calendarVariant.lastStart.date,
        "YYYY-MM-DD"
      );
      const end_date = moment(
        this.$refs.calendarVariant.lastEnd.date,
        "YYYY-MM-DD"
      );

      const events = this.liveEvents.filter((x) => {
        const eventStartDate = moment(x.start_date, "YYYY-MM-DD");
        if (eventStartDate >= start_date && eventStartDate <= end_date) {
          return true;
        }
        return false;
      });

      if (events.length) {
        this.setCopiedEventData(JSON.parse(JSON.stringify(events)));

        this.$toast.success(`Current calendar page has been copied!`, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        console.log(events);
      }
    },

    async handleOnEventInsert(event, isCopied) {
      try {
        // this.createLoading = true;
        const eventData = this.events.find((x) => x.id === event.event_id);

        if (event.isRecurring) {
          var periodLength = 0;
          var start_date = moment(event.start_date, "YYYY-MM-DD HH:mm");
          var end_date = moment(event.end_date, "YYYY-MM-DD HH:mm");

          const eventLength = end_date.diff(start_date, "seconds");
          const tempId = this.$generateRandomId(6);
          for (let i = 0; i < event.recurringOptions.amount; i++) {
            if (event.recurringOptions.period.mode === "Days") {
              periodLength = event.recurringOptions.period.value * 86400;
            } else if (event.recurringOptions.period.mode === "Hours") {
              periodLength = event.recurringOptions.period.value * 3600;
            } else if (event.recurringOptions.period.mode === "Minutes") {
              periodLength = event.recurringOptions.period.value * 60;
            }

            if (i === 0) {
              this.liveEvents.push({
                id: null,
                event_id: event.event_id,
                start_date: start_date.format("YYYY-MM-DD HH:mm"),
                end_date: end_date.format("YYYY-MM-DD HH:mm"),
                isRecurring: event.isRecurring,
                recurringOptions: event.recurringOptions,
                tempId: tempId,
                event_name: eventData.event_name,
                alias: eventData.alias,
                copied: false,
                type_icon: eventData.type.icon,
                color: eventData.type.color,
                type_id: eventData.type.id,
                vuetify_color: eventData.type.vuetify_color,
              });
            } else {
              this.liveEvents.push({
                id: null,
                event_id: event.event_id,
                start_date: start_date,
                end_date: end_date,
                isRecurring: event.isRecurring,
                recurringOptions: event.recurringOptions,
                tempId: tempId,
                event_name: eventData.event_name,
                alias: eventData.alias,
                copied: true,
                type_icon: eventData.type.icon,
                color: eventData.type.color,
                type_id: eventData.type.id,
                vuetify_color: eventData.type.vuetify_color,
              });
            }

            start_date = moment(end_date, "YYYY-MM-DD HH:mm").add(
              periodLength,
              "seconds"
            );

            if (event.recurringOptions.period.mode === "Days") {
              const baseStartDate = moment(
                event.start_date,
                "YYYY-MM-DD HH:mm"
              );

              start_date = start_date.set({
                hours: baseStartDate.hours(),
                minutes: baseStartDate.minutes(),
                seconds: baseStartDate.seconds(),
              });
            }

            start_date = start_date.format("YYYY-MM-DD HH:mm");

            end_date = moment(start_date, "YYYY-MM-DD HH:mm")
              .add(eventLength, "seconds")
              .format("YYYY-MM-DD HH:mm");
          }
        } else {
          if (!this.$isArr(event)) {
            this.liveEvents.push({
              id: null,
              event_id: event.event_id,
              start_date: event.start_date,
              end_date: event.end_date,
              isRecurring: event.isRecurring,
              recurringOptions: event.recurringOptions,
              tempId: this.$generateRandomId(6),
              event_name: eventData.name,
              alias: eventData.alias,
              type_icon: eventData.type.icon,
              color: eventData.type.color,
              type_id: eventData.type.id,
              vuetify_color: eventData.type.vuetify_color,
            });
          } else if (this.$isArr(event)) {
            this.liveEvents = [
              ...this.liveEvents,
              ...event.map((x) => ({
                ...x,
                id: null,
                tempId: this.$generateRandomId(6),
              })),
            ];
          }

          if (isCopied) {
            // Takvimi yapıştırılan ay'a götür.
            this.value = moment.unix(event.start_date).format("YYYY-MM-DD");
          }
        }

        this.$toast.success("You have successfully inserted the live event.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.createLoading = false;
      }
    },

    async handleOnEventDelete(data, withRecurrings) {
      this.showUpdateDialog = false;
      this.showUpdateDialogData = null;
      // Delete live event from deployment.
      if (data.id) {
        const index = this.liveEvents.findIndex((x) => x.id === data.id);
        if (index > -1) {
          if (withRecurrings) {
            this.liveEvents = this.liveEvents.filter(
              (x) => x.event_id !== data.event_id
            );
          } else {
            this.liveEvents.splice(index, 1);
          }
        }
      } else {
        if (data.tempId) {
          this.liveEvents = this.liveEvents.filter(
            (x) => x.tempId != data.tempId
          );
          this.$toast.success(
            "You have successfully removed the live event from calendar.",
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );
        }
      }
    },
    async handleOnEventUpdate(data) {
      try {
        const idx = this.liveEvents.findIndex((x) => x.id === data.id);
        if (idx > -1) {
          this.liveEvents[idx].start_date = data.start_date;
          this.liveEvents[idx].end_date = data.end_date;

          const eventIdx = this.events.findIndex((x) => x.id === data.event_id);

          if (eventIdx > -1) {
            this.liveEvents[idx].event_name = this.events[eventIdx].name;
            this.liveEvents[idx].type_icon = this.events[eventIdx].type.icon;
            this.liveEvents[idx].type_id = this.events[eventIdx].type.id;
            this.liveEvents[idx].color = this.events[eventIdx].type.color;
            this.liveEvents[idx].vuetify_color =
              this.events[eventIdx].type.vuetify_color;
            this.liveEvents[idx].alias = this.events[eventIdx].alias;
          }

          this.$toast.success("You have successfully updated the variant.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.createLoading = false;
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    returnMonth(dateString) {
      const date = new Date(dateString);
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Get the month index from the date object (0-based index)
      const monthIndex = date.getMonth();

      // Return the month name corresponding to the month index
      return `${monthNames[monthIndex]} - ${date.getFullYear()}`;
    },
    isEventDeleted(event) {
      const events = this.liveEvents.filter((x) => x.delete);
      for (let i = 0; i < events.length; i++) {
        const deletedEvent = events[i];
        if (deletedEvent.id === event.id) return true;
        if (
          deletedEvent.event_id === event.event_id &&
          deletedEvent.deleteWithRecurrings
        )
          return true;
      }
      return false;
    },
    handleOnCalendarEvent({ event }) {
      this.showUpdateDialogData = JSON.parse(JSON.stringify(event));
      this.showUpdateDialogMode = "update";
      this.showUpdateDialog = true;
    },
    handleOnClickDay(day) {
      this.showUpdateDialogData = null;
      this.insertDay = day.date;
      this.showUpdateDialogMode = "insert";
      this.showUpdateDialog = true;
    },
    handleOnChangeCalendar(event) {
      this.currentDate = event;
    },
    handleShowEventClosedDialog() {
      this.showEventInformationDialog = false;
    },
  },
  components: {
    VariantUpdateDialog,
  },
  computed: {
    ...mapGetters(["getCopiedEventData"]),
    isPasteBtnDisabled() {
      if (!this.getCopiedEventData) return true;

      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
