<template>
  <div>
    <div class="bg-white p-6">
      <div class="flex items-center justify-between">
        <h1 class="font-semibold text-[18px] w-full">Experiments</h1>
        <div class="flex items-center gap-2">
          <v-select
            clearable
            flat
            label="Game"
            v-model="selectedGame"
            dense
            outlined
            class="text-[12px]"
            hide-details
            :items="games"
            item-text="title"
            item-value="applovin_android_package_name"
            style="width: 270px"
          >
          </v-select>

          <v-select
            clearable
            flat
            label="Choose Environment"
            v-model="selectedEnv"
            dense
            outlined
            class="text-[12px]"
            hide-details
            :items="environments"
            item-text="title"
            item-value="value"
            style="width: 270px"
          >
          </v-select>
        </div>
      </div>
      <div class="mt-3">
        <v-data-table
          :headers="headers"
          :items="experiments"
          :loading="loading"
          :items-per-page="20"
        >
          <template v-slot:[`item.id`]="{ item }">
            <span class="font-semibold text-[11px]">
              {{ item.id }}
            </span>
          </template>

          <template v-slot:[`item.title`]="{ item }">
            <div class="font-semibold text-[11px] flex items-center">
              {{ item.title }}

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span class="pl-1" v-bind="attrs" v-on="on">
                    <v-icon size="16">mdi-information</v-icon>
                  </span>
                </template>
                <span>{{ item.description }}</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.rollout`]="{ item }">
            <span class="font-semibold text-[11px]">
              {{ item.rollout }} %
            </span>
          </template>

          <template v-slot:[`item.platform`]="{ item }">
            <span class="font-semibold text-[11px] capitalize">
              {{ item.platform }}
            </span>
          </template>

          <template v-slot:[`item.start_date`]="{ item }">
            <span class="font-semibold text-[11px]">
              {{ formattedDate(item.start_date) }}
            </span>
          </template>

          <template v-slot:[`item.end_date`]="{ item }">
            <span class="font-semibold text-[11px]">
              {{ formattedDate(item.end_date) }}
            </span>
          </template>

          <template v-slot:[`item.status_text`]="{ item }">
            <span
              class="font-semibold text-[11px] uppercase inline-block px-3 py-1 rounded-full"
              :class="{
                'bg-yellow-100 text-yellow-700':
                  getDynamicStatusText(
                    item.start_date,
                    item.end_date,
                    item.discard
                  ) === 'pending',
                'bg-green-100 text-green-700':
                  getDynamicStatusText(
                    item.start_date,
                    item.end_date,
                    item.discard
                  ) === 'active',
                'bg-rose-100 text-rose-700':
                  getDynamicStatusText(
                    item.start_date,
                    item.end_date,
                    item.discard
                  ) === 'discard',
                'bg-orange-100 text-orange-700':
                  getDynamicStatusText(
                    item.start_date,
                    item.end_date,
                    item.discard
                  ) === 'over',
              }"
            >
              {{
                getDynamicStatusText(
                  item.start_date,
                  item.end_date,
                  item.discard
                )
              }}
            </span>
          </template>

          <template v-slot:[`item.number_of_users`]="{ item }">
            <div class="font-semibold text-[11px] flex items-center">
              {{ item.number_of_users ? item.number_of_users : "-" }}

              <v-tooltip bottom v-if="item.number_of_users">
                <template v-slot:activator="{ on, attrs }">
                  <span class="pl-1" v-bind="attrs" v-on="on">
                    <v-icon size="16">mdi-chart-bar</v-icon>
                  </span>
                </template>
                <div>
                  Variant A: {{ Math.round(item.number_of_users * 0.25) }}
                  <br />
                  Variant B: {{ Math.round(item.number_of_users * 0.75) }}
                </div>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="flex items-center">
              <v-btn
                color="secondary"
                text
                small
                class="text-[11px] font-semibold"
                @click="previewExperiment(item)"
              >
                <v-icon size="14">mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                color="error"
                text
                small
                class="text-[11px] font-semibold"
                :disabled="!['active', 'pending'].includes(item.status_text)"
              >
                <v-icon size="14">mdi-stop</v-icon>
              </v-btn>

              <v-btn
                color="success"
                text
                small
                class="text-[11px] font-semibold"
                :disabled="!['active', 'pending'].includes(item.status_text)"
              >
                <v-icon size="14">mdi-rocket-launch-outline</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>

    <PreviewExperimentDialog
      :show="showExperimentDetails"
      :experiment_id="selectedExperimentId"
      :env="selectedEnv"
      @closed="handleOnExperimentDialogPreviewClose"
    />
  </div>
</template>

<script>
import moment from "moment";
import PreviewExperimentDialog from "@/components/tools/game_backend/PreviewExperimentDialog.vue";

export default {
  data() {
    return {
      games: [],
      selectedGame: null,
      environments: [
        {
          title: "Test",
          value: "test",
        },
        {
          title: "Production",
          value: "prod",
        },
      ],
      headers: [
        {
          text: "ID",
          value: "id",
          sortable: true,
        },
        {
          text: "Title",
          value: "title",
          sortable: true,
        },
        {
          text: "Start Date",
          value: "start_date",
          sortable: true,
        },
        {
          text: "End Date",
          value: "end_date",
          sortable: true,
        },
        {
          text: "Status",
          value: "status_text",
          sortable: true,
        },
        {
          text: "Number of Users",
          value: "number_of_users",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      experiments: [],
      loading: false,
      selectedEnv: null,
      showExperimentDetails: false,
      selectedExperimentId: null,
    };
  },
  methods: {
    fetchGames() {
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          this.games = response.data.filter(
            (x) => x.applovin_android_package_name
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDynamicStatusText(start_date, end_date, discard) {
      const start = moment(start_date);
      const end = moment(end_date);
      const current = moment();

      if (discard) {
        return "discard";
      } else if (start <= current && current <= end) {
        return "active";
      } else if (current < start && current < end) {
        return "pending";
      } else if (current > start && current >= end) {
        return "over";
      }

      return "-";
    },
    handleOnExperimentDialogPreviewClose() {
      this.selectedExperimentId = null;
      this.showExperimentDetails = false;
    },
    previewExperiment(data) {
      this.selectedExperimentId = data.id;
      this.showExperimentDetails = true;
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    async fetchExperiments() {
      try {
        if (!this.selectedEnv) return;
        this.loading = true;

        const request = await this.$api.get(
          `game-backend/experiments/all?env=${this.selectedEnv}`
        );
        this.experiments = request.data
          .map((x) => ({
            ...x,
            number_of_users: ["active", "over", "discard"].includes(
              x.status_text
            )
              ? Math.floor(Math.random() * (100000 - 1 + 1) + 1)
              : null,
          }))
          .filter((x) => x.package_name === this.selectedGame);
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    formattedDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  watch: {
    selectedGame(newValue) {
      if (newValue && this.selectedEnv) {
        this.fetchExperiments();
      }
    },
    selectedEnv(newValue) {
      if (newValue && this.selectedGame) {
        this.fetchExperiments();
      }
    },
  },
  mounted() {
    this.fetchGames();
  },
  components: {
    PreviewExperimentDialog,
  },
};
</script>

<style lang="scss" scoped></style>
