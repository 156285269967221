import { render, staticRenderFns } from "./CreateNewExperiment.vue?vue&type=template&id=c5f71c8e&scoped=true"
import script from "./CreateNewExperiment.vue?vue&type=script&lang=js"
export * from "./CreateNewExperiment.vue?vue&type=script&lang=js"
import style0 from "./CreateNewExperiment.vue?vue&type=style&index=0&id=c5f71c8e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5f71c8e",
  null
  
)

export default component.exports