<template>
  <div>
    <v-dialog v-model="visible" width="600" persistent>
      <v-card>
        <v-card-title class="text-h5">
          Event Schedule A/B Experiment
        </v-card-title>

        <v-card-text>
          <div class="pt-5">
            <div>
              <v-text-field
                class="text-[13px]"
                v-model="experimentData.title"
                label="Experiment Title"
                outlined
                dense
                hide-details
              ></v-text-field>
            </div>
            <div class="mt-3">
              <v-text-field
                class="text-[13px]"
                v-model="experimentData.description"
                label="Experiment Description"
                outlined
                dense
                hide-details
              ></v-text-field>
            </div>
            <div class="mt-3">
              <v-select
                class="text-[13px]"
                v-model="experimentData.platform"
                label="Platform"
                outlined
                dense
                hide-details
                clearable
                :items="['Android', 'iOS', 'Both']"
              ></v-select>
            </div>
            <div class="mt-3">
              <h1 class="font-semibold text-[11] mb-5 text-gray-900">
                Rollout (%)
              </h1>
              <v-slider
                v-model="experimentData.rollout"
                thumb-label="always"
                hide-details
                :min="0"
                :max="100"
              ></v-slider>
            </div>
            <h1 class="font-semibold text-[11px] mt-5 mb-2 text-gray-900">
              Optional Filters
            </h1>
            <div class="mt-3">
              <v-autocomplete
                class="text-[13px]"
                v-model="experimentData.countries"
                label="Countries (Optional)"
                outlined
                dense
                hide-details
                clearable
                multiple
                :items="computedCountries"
                item-text="name"
                item-value="code"
              ></v-autocomplete>
            </div>
            <div class="mt-3 flex items-center gap-2">
              <div class="w-8/12">
                <v-text-field
                  class="text-[13px]"
                  v-model="experimentData.version"
                  label="Version (Optional)"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </div>
              <div class="w-4/12">
                <v-select
                  class="text-[13px]"
                  v-model="experimentData.version_condition"
                  label="Condition"
                  outlined
                  dense
                  hide-details
                  clearable
                  :items="[
                    'Lower than',
                    'Greater than',
                    'Equal',
                    'Equal or lower than',
                    'Equal or greater than',
                  ]"
                ></v-select>
              </div>
            </div>

            <div class="mt-3">
              <v-divider></v-divider>
            </div>

            <div class="mt-3">
              <v-menu
                v-model="start_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="text-[13px]"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    v-model="experimentData.start_date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="start_date_menu = false"
                  v-model="experimentData.start_date"
                ></v-date-picker>
              </v-menu>
            </div>

            <div class="mt-3">
              <v-menu
                v-model="end_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="text-[13px]"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    v-model="experimentData.end_date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="end_date_menu = false"
                  v-model="experimentData.end_date"
                  :allowed-dates="allowedDates"
                ></v-date-picker>
              </v-menu>
            </div>

            <div class="mt-3 text-right text-gray-600">
              <p class="m-0 p-0 text-[12px]">
                Around <span class="font-semibold">30k</span> users will be
                affected from this experiment.
              </p>
            </div>

            <h1 class="font-semibold text-[11px] mt-5 mb-2 text-gray-900">
              Variants
            </h1>

            <div class="mt-3 gap-3 flex flex-col">
              <div
                v-for="(variant, index) in experimentData.variants"
                :key="index"
              >
                <fieldset class="p-4 border rounded">
                  <legend class="text-[10px] font-semibold px-2">
                    {{ index !== 0 ? `Variant ${variant.id}` : "Default (A)" }}
                  </legend>
                  <div
                    class="hover:border-gray-300 flex items-center justify-between"
                  >
                    <div class="w-full">
                      <input
                        type="text"
                        v-model="variant.group"
                        class="customInput w-[230px] hover:bg-gray-100 focus:bg-gray-100 transition duration-200 border border-gray-200 rounded-[5px] p-3 text-[12px] outline-0"
                        style="border-style: solid"
                        :placeholder="accessibleGroups[index]"
                      />
                    </div>
                    <div class="flex items-center gap-2">
                      <v-btn
                        color="blue darken-1"
                        text
                        small
                        :ripple="false"
                        @click="showVariantEditDialog(index)"
                        :disabled="variant.id === 'A'"
                      >
                        Edit Calendar
                      </v-btn>
                      <v-btn
                        color="red darken-1"
                        text
                        small
                        :ripple="false"
                        @click="removeVariant(index)"
                        :disabled="
                          ['A'].includes(variant.id) ||
                          experimentData.variants.length <= 2
                        "
                      >
                        Delete
                      </v-btn>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div
                v-if="experimentData.variants.length < 5"
                class="p-4 rounded text-gray-500 text-[11px] hover:border-gray-300 hover:text-gray-700 font-semibold text-center cursor-pointer border border-dashed"
                :class="{
                  'col-span-4': experimentData.variants.length === 1,
                  'col-span-3': experimentData.variants.length === 2,
                  'col-span-2': experimentData.variants.length === 3,
                  'col-span-1': experimentData.variants.length === 4,
                }"
                @click="createNewVariant"
              >
                New Variant
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <v-btn color="red darken-1" text @click="visible = false">
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitExperiment"
              :disabled="isConfirmBtnDisabled"
              :loading="loading"
            >
              Confirm
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>

      <VariantEditDialog
        :show="showVariantEdit"
        @closed="showVariantEdit = false"
        :data="selectedVariantData"
        :title="selectedVariantTitle"
        :games="games"
        :eventTypes="eventTypes"
        :events="events"
        :index="selectedVariantIndex"
        @submit="submitVariant"
      ></VariantEditDialog>
    </v-dialog>
  </div>
</template>

<script>
import { COUNTRIES } from "@/helper/utils";
import VariantEditDialog from "./VariantEditDialog.vue";
import moment from "moment";

export default {
  data() {
    return {
      visible: false,
      accessibleGroups: ["A", "B", "C", "D", "E"],
      experimentData: {
        title: "",
        description: "",
        platform: null,
        countries: null,
        version: "",
        version_condition: null,
        start_date: null,
        end_date: null,
        variants: [
          {
            id: "A",
            calendarData: [],
            group: "",
          },
          {
            id: "B",
            calendarData: [],
            group: "",
          },
        ],
        rollout: 100,
      },
      selectedVariantData: [],
      selectedVariantTitle: null,
      start_date_menu: false,
      end_date_menu: false,
      showVariantEdit: false,
      selectedVariantIndex: null,
      loading: false,
    };
  },
  components: {
    VariantEditDialog,
  },
  methods: {
    allowedDates(val) {
      if (moment(val) <= moment()) return false;
      return true;
    },
    submitVariant(index, data) {
      this.experimentData.variants[index].calendarData = JSON.parse(
        JSON.stringify(data)
      );
    },
    removeVariant(index) {
      this.experimentData.variants.splice(index, 1);
      for (let i = 0; i < this.experimentData.variants.length; i++) {
        this.experimentData.variants[i].id = this.accessibleGroups[i];
      }
    },
    showVariantEditDialog(index) {
      const variant = this.experimentData.variants[index];
      this.selectedVariantData = JSON.parse(
        JSON.stringify(variant.calendarData)
      );

      this.selectedVariantTitle = variant.id;
      this.showVariantEdit = true;
      this.selectedVariantIndex = index;
    },
    createNewVariant() {
      if (this.experimentData.variants.length < 5) {
        const groups = ["A", "B", "C", "D", "E"];

        this.experimentData.variants.push({
          id: groups[this.experimentData.variants.length],
          calendarData: JSON.parse(JSON.stringify(this.defaultCalendarData)),
        });
      }
    },
    async submitExperiment() {
      const requestData = {
        ...this.experimentData,
        package_name: this.selected_game.applovin_android_package_name,
        start_date: moment(this.experimentData.start_date)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format("YYYY-MM-DDTHH:mm:ss"),
        end_date: moment(this.experimentData.end_date)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format("YYYY-MM-DDTHH:mm:ss"),

        variants: JSON.stringify(
          this.experimentData.variants.map((x) => this.modifyVariantData(x))
        ),
        countries: this.experimentData.countries
          ? JSON.stringify(this.experimentData.countries)
          : null,
        platform: this.experimentData.platform.toLowerCase(),
        version: this.experimentData.version
          ? this.experimentData.version
          : null,
        version_condition: this.experimentData.version_condition
          ? this.experimentData.version_condition.toLowerCase()
          : null,
      };
      try {
        this.loading = true;
        const request = await this.$api.post(
          `game-backend/experiments/create?env=${this.env}`,
          requestData
        );
        if (request.status === 200) {
          this.$toast.success(
            "You have successfully created a new experiment.",
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );

          // this.visible = false;
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    modifyVariantData(variant) {
      return {
        id: variant.id,
        group: variant.group,
        calendarData: this.modifyCalendarData(variant.calendarData),
      };
    },
    modifyCalendarData(data) {
      let modifiedData = [];

      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        const index = modifiedData.findIndex(
          (x) => x.event_id === item.event_id
        );

        if (index === -1) {
          modifiedData.push({
            id: item.event_id,
            type: item.type,
            theme: item.theme,
            payload: item.payload ? JSON.stringify(item.payload) : null,
            schedule: [
              {
                start_date: item.start_date,
                end_date: item.end_date,
              },
            ],
          });
        } else {
          modifiedData[index].schedule.push({
            start_date: item.start_date,
            end_date: item.end_date,
          });
        }
      }

      return modifiedData;
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    games: {
      type: Array,
      default: () => [],
    },
    eventTypes: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Array,
      default: () => [],
    },
    defaultCalendarData: {
      type: Array,
      default: () => [],
    },
    selected_game: {
      type: Object,
      default: () => null,
    },
    env: {
      type: String,
      default: null,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
    defaultCalendarData(newValue) {
      if (newValue) {
        this.experimentData.variants[0].calendarData = JSON.parse(
          JSON.stringify(newValue)
        );
        this.experimentData.variants[1].calendarData = JSON.parse(
          JSON.stringify(newValue)
        );
      }
    },
  },
  computed: {
    computedCountries() {
      return COUNTRIES;
    },
    isConfirmBtnDisabled() {
      // Zorunlu alanları kontrol et.
      if (!this.env) return true;
      if (!this.selected_game) return true;
      if (!this.experimentData.title) return true;
      if (!this.experimentData.description) return true;
      if (!this.experimentData.platform) return true;
      if (this.experimentData.version && !this.experimentData.version_condition)
        return true;
      if (!this.experimentData.start_date) return true;
      if (!this.experimentData.end_date) return true;
      if (!this.experimentData.rollout) return true;

      // Başlangıç tarihi bitişten daha kısa olamaz.
      if (
        moment(this.experimentData.start_date) >
        moment(this.experimentData.end_date)
      )
        return true;

      // Grup isimleri yoksa buton kapalı olmalı.
      for (let i = 0; i < this.experimentData.variants.length; i++) {
        const variant = this.experimentData.variants[i];
        if (!variant.group) return true;
      }

      // Tüm şartlar sağlanıyorsa;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.customInput {
  &::placeholder {
    color: rgba($color: #000000, $alpha: 0.45);
  }

  color: rgba($color: #000000, $alpha: 0.9);
}
</style>
