<template>
  <div class="login-section flex items-center justify-center">
    <div class="login-container md:flex w-full">
      <div
        class="login-image md:w-8/12 h-full hidden md:block"
        :style="{ backgroundImage: `url(${randomImage})` }"
      ></div>
      <div class="login-panel justify-center md:justify-center p-12 md:w-4/12">
        <div class="w-full">
          <div class="flex items-center mb-4 justify-center">
            <div>
              <img src="../../assets/Logo.png" width="64" />
            </div>
            <h1 class="md:text-[48px] text-[28px] md:mb-0 font-bold">Gaia</h1>
          </div>
          <div class="w-full">
            <div class="mt-0 w-full" ref="loginContainer">
              <div
                class="flex items-center justify-center w-full"
                v-if="clientId"
              >
                <div id="google-login-btn" ref="loginBtn">
                  Google ile Devam Et
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from "js-cookie";
import GoogleSignInButton from "vue-google-identity-login-btn";

export default {
  name: "LoginView",
  directives: {
    GoogleSignInButton,
  },
  data() {
    return {
      tab: 0,
      email: "",
      password: "",
      show_password: false,
      loading_psw_login: false,
      loading_social_login: false,
      remember_me: false,
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      images: [
        "https://i.hizliresim.com/c16se0m.jpg",
        "https://i.hizliresim.com/juct774.jpg",
        "https://i.hizliresim.com/hcdg4zo.jpg",
        "https://i.hizliresim.com/3djf7j6.jpg",
        "https://i.hizliresim.com/5075gzt.jpg",
      ],
    };
  },
  methods: {
    // async loginViaGoogle() {
    //   this.loading_social_login = true;
    //   try {
    //     const user = await this.$gAuth.signIn();

    //     if (user && this.$gAuth.isAuthorized) {
    //       const id_token = user.getAuthResponse().id_token;
    //       this.$api
    //         .post("auth/google", {
    //           id_token: id_token,
    //         })
    //         .then((response) => {
    //           const access_token = response.data.access_token
    //             ? response.data.access_token
    //             : null;
    //           if (access_token) {
    //             Cookie.set("gaia_sid", access_token, { expires: 14 });

    //             const web_url =
    //               process.env.NODE_ENV === "development"
    //                 ? "http://localhost:8080"
    //                 : "https://gaia.apps.com.tr";

    //             window.location = web_url;
    //           }
    //         });

    //       this.loading_social_login = false;
    //     }
    //   } catch (error) {
    //     this.loading_social_login = false;
    //   }
    // },

    onGoogleAuthSuccess(response) {
      this.$api
        .post("auth/google", {
          id_token: response.credential,
        })
        .then((response) => {
          const access_token = response.data.access_token
            ? response.data.access_token
            : null;
          if (access_token) {
            Cookie.set("gaia_sid", access_token, { expires: 14 });
            const web_url =
              process.env.NODE_ENV === "development"
                ? "http://localhost:8080"
                : "https://gaia.apps.com.tr";

            window.location = web_url;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    randomImage() {
      const random = Math.floor(Math.random() * this.images.length);
      return this.images[random];
    },
  },
  mounted() {
    const width = this.$refs.loginContainer.offsetWidth;
    this.$nextTick(() => {
      setTimeout(() => {
        if (window.google) {
          window.google.accounts.id.initialize({
            client_id: this.clientId,
            callback: this.onGoogleAuthSuccess,
          });

          window.google.accounts.id.renderButton(this.$refs.loginBtn, {
            type: "standard",
            size: "large",
            width: `${width}px`,
          });
        }
      }, 1000);
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/sass/variables.scss";

.login-section {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: white;

  .login-panel {
    max-width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .login-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .login-container {
    height: 100%;

    ::v-deep label {
      font-size: 14px !important;
    }

    .company-logo {
      height: 35px;
    }

    .product-title {
      font-size: 24px;
      font-weight: 900;
      text-align: center;
      font-family: $sub-font-family;
    }

    ::v-deep
      .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
      > .v-input__control
      > .v-input__slot
      fieldset {
      color: #e0e0e0;
    }

    ::v-deep .v-btn__content {
      font-size: 13px !important;
      font-weight: 600;
      text-transform: capitalize;
    }

    .bottom-buttons {
      ::v-deep .v-input--selection-controls {
        padding-top: 0;
        margin-top: 0;
      }
    }
  }
}

#google-login-btn {
  display: inline-block;
}
</style>
